body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('asserts/newbg.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed; 
    background-size: 100% 100%;
}

.header {
    width: 100%;
    padding: 10px;
    text-align: center;
    background-image: url(asserts/footer.jpg);
    color: white;
}
.footer{
    width: 100%;
    height: auto;
    padding: 5px;
    text-align: center;
    background-image: url(asserts/footer.jpg);
    color: white;
}
.social-links :hover{
    color: white;
}
.container {
    text-align: center;
    background-color: #1f1b2c;
    color: #ffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 40%;
    max-width: 300px;
    margin: 20px auto;
}
.footer{
   font-size: larger;
}

.search-engines {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}
.search-engines {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.search-engine {
    background-color: #e8eef3;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 120px;
    margin: 10px;
}

.search-engine img {
    width: 48px;
    height: 48px;
    text-decoration: none;
}
.a{
    text-decoration: none;
}
.show-matches {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    text-decoration: none;
}
.upload-button {
    background-color: #8c3ef0;
    color: white;
    font-size: larger;
}
.upload-button, .monthly-plan-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}


.show-matches:hover {
    background-color: #0056b3;
}
.main-content {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap in smaller screens */
    max-width: 1200px;
    width: 100%;
}

.content {
    flex: 3;
    padding: 20px;
}

.sidebar {
    flex: 1;
    padding: 20px;
    background-color: #f0f0f0;
}

.ad {
    margin: 20px 0;
    text-align: center;
    background-color: transparent;
    padding: 10px;
    border: 1px solid transparent;
}
.table-container {
    width: 100%;
    border-collapse: collapse;
}

.table-container td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
    vertical-align: middle;
}

.table-container td:first-child {
    width: 40px;
    text-align: center;
}

.table-container td:last-child {
    text-align: left;
}
/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .main-content {
        flex-direction: column; /* Stack content and sidebar vertically */
    }
    .content, .sidebar {
        width: 100%;
        padding: 10px;
    }
}

@media (max-width: 480px) {
    .ad {
        padding: 5px;
        font-size: 14px; /* Adjust font size for smaller screens */
    }
}
